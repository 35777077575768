export interface TableState {
  tableType: AdminTableSelection;
  tableData:
    | MovieScoreAsset[]
    | AdminAsset[]
    | Platform[]
    | CustomerComment[]
    | ShowsAsset[]
    | undefined
    | any[];
  assetDetail: AssetDetail[] | undefined;
  tableColumns: [];
  loading: boolean;
  adminDrawer: {
    open: boolean;
    values: any | {};
    rowIndex: string | undefined;
  };
  filterToggle: {
    released: boolean;
    searchable: boolean;
    noPoster: boolean;
    noIMDB: boolean;
    classification: boolean;
  };
  classifications: Classificaitons[];
}

export interface AdminAsset {
  asset_id: number;
  asset_identifier_id: number;
  imdb_id?: any;
  img_URL?: any;
  name: string;
  display_name?: any;
  release_date: string;
  duration: string;
  credit_start_time?: any;
  filter_released: boolean;
  platform_id: number;
  filter_release_date: string;
  searchable: boolean;
  filter_id: number;
  classification_version_id: number;
}

export interface MovieScoreAsset {
  clearplay?: any;
  comment?: string | null;
  date: string;
  score: number;
  userId: string;
  email: string;
  count?: number;
}

export interface Platform {
  platform_id: number;
  name: string;
  time_offset: number;
  requires_membership: boolean;
  enabled: boolean;
  maintenanceMessage: string;
  moviesActive: boolean;
  showsActive: boolean;
  isSearchable: boolean;
  minimumVersion: string;
  web_banner: boolean;
  web_shelves: boolean;
  web_search: boolean;
}

export interface AssetDetail {
  clearplay?: any;
  comment?: string | null;
  date: string;
  score: number;
  userId: string;
  email: string;
  count?: number;
}

export interface CustomerComment {
  asset_score_id: number;
  comment: string;
  email: string;
  imdb_id: string;
  modified_at: string;
  platform_id: string;
  score: number;
  user_id: string;
  total_votes: number;
  display_name: string;
  resolved_at: string | null;
  reported_at: string | null;
  partner_identifier: number;
  filter_release_date: string;
  created_at: string;
  released: boolean;
  asset_type_id: number;
  platform_title: string;
  extension_version?: any;
  url?: any;
  browser_name: string;
  browser_version: string;
  device_type: string;
  os_name: string;
  os_version_name: string;
  os_version: string;
  unreproducible_at: string
}

export interface ShowsAsset {
  episode_asset_id: number;
  episode_asset_identifier_id: number;
  episode_credit_start_time?: any;
  episode_display_name: string;
  episode_duration?: any;
  episode_filter_release_date: string;
  episode_filter_released: boolean;
  episode_imdb_id: string;
  episode_name: string;
  episode_number: number;
  episode_partner_identifier: string;
  episode_platform_id: number;
  season_asset_id: number;
  season_number: number;
  series_asset_id: number;
  series_display_name: string;
  series_imdb_id: string;
  series_img_URL: string;
  series_name: string;
  series_searchable: boolean;
  episode_classification_version_id: number;
}

export interface Classificaitons {
  classification_version_id: number;
  description: number;
}

export enum AdminTableSelection {
  MOVIE_SCORE_ASSET = 'scores',
  PLATFORM_CONTROLS = 'platforms',
  MOVIE_ASSET = 'movies',
  CUSTOMER_COMMENTS = 'scores/comments',
  SHOW_ASSET = 'shows',
  SHELVES = 'shelves',
  STATS = 'stats',
  DURATION_MISMATCHES = 'durationMismatches',
  SKIP_ACCURACIES = 'skipResumeAccuracies',
  SNEEZ_TEST = 'sneezeTests',
  FEEDBACK_FILTERING = 'filterings'
}

export const labelMap: { [key: string]: any } = {
  display_name: 'Display Name',
  imdb_id: 'Imdb Id',
  release_date: 'Theatrical Release Date',
  filter_release_date: 'Filter Release Date',
  credit_start_time: 'Credit Start Time',
  searchable: 'Searchable',
  episode_display_name: 'Episode Display Name',
  episode_imdb_id: 'Episode Imdb Id',
  episode_release_date: 'Theatrical Release Date',
  episode_filter_release_date: 'Filter Release Date',
  episode_credit_start_time: 'Episode Credit Start Time',
  series_searchable: 'Series Searchable',
  series_name: 'Series Internal Name',
  series_imdb_id: 'Series Imdb Id',
  series_display_name: 'Series Display Name',
  filter_released: 'Filter Released',
  episode_duration: 'Episode Duration',
  episode_name: 'Episode Internal Name',
  asset_list_type_id: 'Asset List Type ID',
  name: 'Shelf Name',
  description: 'Shelf Description',
  IsActive: 'Shelf Active',
  priority: 'Shelf Priority',
  duration: 'Duration',
  isActive: 'Active',
  series_platform_title: 'Series Platform Title', 
  series_video_id: 'Series Video ID',
  episode_platform_id: 'Episode Platform ID', 
}

export enum FetchTableDataUtilQueryKeysEnum {
  FETCH_CLASSIFICATIONS = 'fetchClassifications',
  FETCH_SHELF_LIST = 'fetchShelfList',
  FETCH_SORT_TYPES = 'fetchSortTypes',
  FETCH_PLATFORM_CONFIGS = 'fetchPlatformConfigs'
}



export interface PlatformConfigObject {
  'amazon.com': PlatformConfig;
  'netflix.com': PlatformConfig;
  'disneyplus.com': PlatformConfig;
  'max.com': PlatformConfig;
  'apple.com': PlatformConfig;
}

interface PlatformConfig {
  hostname: string;
  minimum_version: string;
  movies_active: boolean;
  platform_id: number;
  requires_membership: boolean;
  searchable: boolean;
  shows_active: boolean;
  time_offset: number;
  web_banner: boolean;
  web_enabled: boolean;
  web_search: boolean;
  web_shelves: boolean;
  platform_enabled: boolean;
  platform_maintenance_message: string;
  channels: Channel[];
}

interface Channel {
  channel_id: number;
  channel_disabled: boolean;
  channel_display_name: string;
  channel_maintenance_message?: any;
  channel_maintenance_mode: boolean;
  channel_name: string;
}